/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { HOME_PAGE, LOGIN_PAGE, NEW_PROJECT_PAGE, PROJECTS_LIST_PAGE, REGISTER_PAGE, STATUS_PROJECT_PAGE, WAIT_FIFTYONE_PAGE } from './constants/urls';
import Loading from './components/Loading';

import store from './store/store';
import configureAxios from './utils/configureAxios';


import reportWebVitals from './reportWebVitals';

import "@fontsource/montserrat/300.css";
import './index.css';
import { ThemeProvider, createMuiTheme, createTheme } from '@mui/material';
// import StripeWrapper from './views/StripeWrapper';


const HomePage = React.lazy(() => import('./views/HomePage'))
// const UserRegistrationPage = React.lazy(() => import('./views/UserRegistrationPage'))
const StripeWrapper = React.lazy(() => import('./views/StripeWrapper'))
const LoginPage = React.lazy(() => import('./views/LoginPage'))
const ProjectPage = React.lazy(() => import('./views/CreateProject'))
const StatusProject = React.lazy(() => import('./views/StatusProject'))
const ProjectList = React.lazy(() => import('./views/ListOfProjects'))
const WaitFiftyOnePage = React.lazy(() => import('./views/WaitFiftyOne'))


configureAxios();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});

const THEME = createTheme({
  typography: {
    "fontFamily": `"Montserrat", "Helvetica", "Arial", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={THEME}>
    <React.StrictMode>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <HashRouter>
            <Routes>
              <Route path={HOME_PAGE} element={
                <Suspense fallback={<Loading />}>
                  <HomePage />
                </Suspense>
              } />
              <Route path={REGISTER_PAGE} element={
                <Suspense fallback={<Loading />}>
                  <StripeWrapper />
                  {/* <UserRegistrationPage /> */}
                </Suspense>
              } />
              <Route path={LOGIN_PAGE} element={
                <Suspense fallback={<Loading />}>
                  <LoginPage />
                </Suspense>
              } />
              <Route path={NEW_PROJECT_PAGE} element={
                <Suspense fallback={<Loading />}>
                  <ProjectPage />
                </Suspense>
              } />
              <Route path={PROJECTS_LIST_PAGE} element={
                <Suspense fallback={<Loading />}>
                  <ProjectList />
                </Suspense>
              } />
              <Route path={STATUS_PROJECT_PAGE} element={
                <Suspense fallback={<Loading />}>
                  <StatusProject />
                </Suspense>
              } />
              <Route path={WAIT_FIFTYONE_PAGE} element={
                <Suspense fallback={<Loading />}>
                  <WaitFiftyOnePage />
                </Suspense>
              } />
              < Route path='*' element={<div> Page not found </div>} />
            </Routes>
          </HashRouter>
        </QueryClientProvider>
      </Provider>
    </React.StrictMode>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
