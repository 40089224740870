export const HOME_PAGE = '/';
export const LOGIN_PAGE = '/login';
export const REGISTER_PAGE = '/register';

// access after logged user
export const NEW_PROJECT_PAGE = '/project';
export const PROJECTS_LIST_PAGE = '/projectslist';
export const STATUS_PROJECT_PAGE = '/project/status';
export const PANEL_PAGE = '/panel';
export const WAIT_FIFTYONE_PAGE = '/loading';

// access if user is in ADMIN role
export const ADMIN_PAGE = '/admin';