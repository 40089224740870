import axios from "axios";
// import { JWT_ACCESS_TOKEN } from "../../constants/auth";

const configureAxios = () => {
    // const accessToken = localStorage.getItem(JWT_ACCESS_TOKEN);
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    // if (accessToken)
    //     axios.defaults.headers['X-Auth'] = `Bearer ${accessToken}`;
}

export default configureAxios;